import { FC, useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Player } from '@common/clients/api';
import { CountryFlag } from '@web/atoms/CountryFlag';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { Row, StatsTable } from '@web/molecules/StatsTable';

import { getPlayerColumns, getStaffColumns, isPlayer, PlayerColumns, StaffColumns } from './SelectionColumns';

export interface Props {
    items?: Player[];
    title?: string;
}

export const SelectionTable: FC<Props> = ({ items, title }) => {
    const __stats = useTranslation('stats').t;

    const columns = useMemo(() => {
        const playerTable = items?.every((person) => isPlayer(person));
        return playerTable ? getPlayerColumns(title, __stats) : getStaffColumns(title, __stats);
    }, [items, title, __stats]) as PlayerColumns | StaffColumns;

    const createPlayerItems = useCallback((player: Player) => {
        let playerInfo: any = {};

        if (isPlayer(player)) {
            playerInfo = {
                ...playerInfo,
                ...player.stats,
                counterTag: player.shirtNumber, // counterTag will be used as rank but will never highlight the heighest rank
            };
        }

        playerInfo.name = !player.tag?.tagSlug ? (
            <>{player.name}</>
        ) : (
            <InternalLink route={Route.Player} query={{ tag: player!.tag!.tagSlug }}>
                {player.name}
            </InternalLink>
        );

        if (player.countryCode) {
            playerInfo.name = (
                <>
                    <CountryFlag src={player.countryCode} width={23} />
                    {playerInfo.name}
                </>
            );
        }

        return playerInfo;
    }, []);

    const total: Row = {};

    const data = useMemo(
        () => (!items?.length ? null : items.map((player) => createPlayerItems(player))),
        [createPlayerItems, items],
    );

    if (!data) {
        return <></>;
    }
    return <StatsTable data={data} columns={columns} total={total} />;
};
