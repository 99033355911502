import { FC, useState } from 'react';

import { CountryUtil } from '@common/utils/CountryUtil';

import { StaticImage } from '../StaticImage';

import styles from './CountryFlag.module.scss';

interface ImageLoaderProps {
    src: string;
    width: number;
}

export interface Props extends ImageLoaderProps {
    alt?: string;
    className?: string;
}
const FALLBACK_IMAGE_SOURCE = '/images/flags/ww.png';
const availableWidths = [23, 46, 69];
const availableHeights = [17, 34, 51];
const getImageSize = (width: number): number => {
    return availableWidths.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
};

const calculateImageSizeProperties = (width: number) => {
    const imageWidth = getImageSize(width);
    const indexWidth = availableWidths.indexOf(imageWidth);
    const imageHeight = availableHeights.at(indexWidth);
    return { imageWidth, imageHeight };
};
const getFlagSource = (countryCode: string) => {
    if (countryCode.length === 3) {
        const countryIso2 = CountryUtil.getCountryISO2(countryCode);
        countryCode = countryIso2;
    }

    countryCode = countryCode?.toLowerCase();
    return `/images/flags/${countryCode}.png`;
};

export const CountryFlag: FC<Props> = ({ src, width, alt, className }) => {
    const sizeProps = calculateImageSizeProperties(width);
    const [useFallback, setUseFallback] = useState<boolean>(false);

    return (
        <span className={[styles.CountryFlag, className, 'country-flag'].join(' ')}>
            <StaticImage
                src={useFallback ? FALLBACK_IMAGE_SOURCE : getFlagSource(src)}
                alt={alt || ''}
                width={sizeProps.imageWidth}
                height={sizeProps.imageHeight}
                onError={() => {
                    setUseFallback(true);
                }}
            />
        </span>
    );
};
